.site-main, .footer {
  opacity: 0;
  transform: translateY(-3em);
  transition: opacity 0.4s ease-in, transform 0.3s ease-in-out;
  transition-delay: 0.5s; 
}

body.loaded .site-main {
  opacity: 1;
  transform: translateY(0);
 }

 body.loaded .footer {
  opacity: 1;
  transform: translateY(0);
 }

.site-navbar {
  opacity: 0;
  transform: translateY(-3em);
  transition: opacity 0.3s ease-in, transform 0.3s ease-in-out; 
  transition-delay: 0.4s; 
}

body.loaded .site-navbar{
  opacity: 1;
  transform: translateY(0); 
}

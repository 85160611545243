@font-face {
  font-family: externalFont;
  src: url(fonts/miramax_font.ttf);
}

.logo {
  font-family: externalFont;
  letter-spacing: -0.10em;
}

/* Base */
body {
  max-width: 960px;
  margin-right: auto;
  margin-left: auto;
}

.items-center {
  justify-content: center;
}

body {
  line-height: 1.7;
  color: #888;
  font-weight: 300;
  font-size: 1rem;
  background: #000;
}

.large-font {
  font-size: 1.5em;
}

ul {
  list-style-type: none;
  padding: 0;
}

.table td {
  padding: 0.3em;
}

.input-group-text {
  border-radius: 0;
}

.table td {
  vertical-align: middle !important;
}

.btn {
  border-radius: 0;
}

.btn:hover,
.btn:active,
.btn:focus {
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

a:hover {
  text-decoration: none;
}

.form-control {
  height: 43px;
  border-radius: 0;
  background: transparent;
  border-color: #777;
  color: #fff !important;
}

.form-control:active,
.form-control:focus {
  border-color: #20c997;
  background: transparent;
}

.form-control:hover,
.form-control:active,
.form-control:focus {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.site-section {
  padding: 2.5em 0;
}

@media (min-width: 768px) {
  .site-section {
    padding: 4em 0;
  }
}

.site-section-heading {
  color: #fff;
  position: relative;
  font-size: 2.5rem;
}

@media (min-width: 768px) {
  .site-section-heading {
    font-size: 4rem;
  }
}

@media (max-width: 992px) {
  .site-section>div>div>div>p {
    padding-top: 5em;
  }

  .site-section {
    padding-bottom: 0;
  }
}

input::selection,
textarea::selection {
  color: #000;
  background: #fff;
}

.site-section-heading:after {
  content: "";
  left: 0%;
  bottom: 0;
  position: absolute;
  width: 100px;
  height: 1px;
  background: #000;
}

.site-section-heading.text-center:after {
  content: "";
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  content: "";
  left: 50%;
  bottom: 0;
  position: absolute;
  width: 100px;
  height: 1px;
  background: #000;
}

/* Navbar */
.site-navbar {
  margin-bottom: 0px;
  z-index: 9;
  position: relative;
}

.site-navbar .site-navigation .site-menu {
  margin-bottom: 0;
}

.site-navbar .site-navigation .site-menu .active>a {
  color: #20c997;
}

.site-navbar .site-navigation .site-menu a {
  text-decoration: none !important;
  font-size: 15px;
  display: inline-block;
}

.site-navbar .site-navigation .site-menu>li {
  display: inline-block;
  padding: 10px 5px;
}

.site-navbar .site-navigation .site-menu>li>a {
  padding: 10px 10px;
  text-transform: uppercase;
  letter-spacing: .1em;
  color: #888;
  font-size: 12px;
  text-decoration: none !important;
}

.site-navbar .site-navigation .site-menu>li>a:hover {
  color: #20c997;
}

.site-navbar .site-navigation .site-menu .has-children {
  position: relative;
}

.site-navbar .site-navigation .site-menu .has-children>a {
  position: relative;
  padding-right: 20px;
}

.site-navbar .site-navigation .site-menu .has-children .dropdown {
  visibility: hidden;
  opacity: 0;
  top: 100%;
  position: absolute;
  text-align: left;
  border-top: 2px solid #20c997;
  -webkit-box-shadow: 0 2px 10px -2px rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 10px -2px rgba(0, 0, 0, 0.1);
  border-left: 1px solid #edf0f5;
  border-right: 1px solid #edf0f5;
  border-bottom: 1px solid #edf0f5;
  padding: 0px 0;
  margin-top: 20px;
  margin-left: 0px;
  background: #fff;
  -webkit-transition: 0.2s 0s;
  -o-transition: 0.2s 0s;
  transition: 0.2s 0s;
}

.site-navbar .site-navigation .site-menu .has-children .dropdown.arrow-top {
  position: absolute;
}

.site-navbar .site-navigation .site-menu .has-children .dropdown.arrow-top:before {
  bottom: 100%;
  left: 20%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.site-navbar .site-navigation .site-menu .has-children .dropdown.arrow-top:before {
  border-color: rgba(136, 183, 213, 0);
  border-bottom-color: #fff;
  border-width: 10px;
  margin-left: -10px;
}

.site-navbar .site-navigation .site-menu .has-children .dropdown a {
  font-size: 16px;
  text-transform: none;
  letter-spacing: normal;
  -webkit-transition: 0s all;
  -o-transition: 0s all;
  transition: 0s all;
  color: #343a40;
}

.site-navbar .site-navigation .site-menu .has-children .dropdown .active>a {
  color: #20c997 !important;
}

.site-navbar .site-navigation .site-menu .has-children .dropdown>li {
  list-style: none;
  padding: 0;
  margin: 0;
  min-width: 200px;
}

.site-navbar .site-navigation .site-menu .has-children .dropdown>li>a {
  padding: 9px 20px;
  display: block;
}

.site-navbar .site-navigation .site-menu .has-children .dropdown>li>a:hover {
  background: #f4f5f9;
  color: #25262a;
}

.site-navbar .site-navigation .site-menu .has-children .dropdown>li.has-children>a:before {
  content: "\e315";
  right: 20px;
}

.site-navbar .site-navigation .site-menu .has-children .dropdown>li.has-children>.dropdown,
.site-navbar .site-navigation .site-menu .has-children .dropdown>li.has-children>ul {
  left: 100%;
  top: 0;
}

.site-navbar .site-navigation .site-menu .has-children .dropdown>li.has-children:hover>a,
.site-navbar .site-navigation .site-menu .has-children .dropdown>li.has-children:active>a,
.site-navbar .site-navigation .site-menu .has-children .dropdown>li.has-children:focus>a {
  background: #f4f5f9;
  color: #25262a;
}

.site-navbar .site-navigation .site-menu .has-children a {
  text-transform: uppercase;
}

.site-navbar .site-navigation .site-menu .has-children:hover>a,
.site-navbar .site-navigation .site-menu .has-children:focus>a,
.site-navbar .site-navigation .site-menu .has-children:active>a {
  color: #20c997;
}

.site-navbar .site-navigation .site-menu .has-children:hover,
.site-navbar .site-navigation .site-menu .has-children:focus,
.site-navbar .site-navigation .site-menu .has-children:active {
  cursor: pointer;
}

.site-navbar .site-navigation .site-menu .has-children:hover>.dropdown,
.site-navbar .site-navigation .site-menu .has-children:focus>.dropdown,
.site-navbar .site-navigation .site-menu .has-children:active>.dropdown {
  -webkit-transition-delay: 0s;
  -o-transition-delay: 0s;
  transition-delay: 0s;
  margin-top: 0px;
  visibility: visible;
  opacity: 1;
}

.bg-light {
  background: #ccc;
}

.image-wrap-2 {
  display: block;
  overflow: hidden;
  top: 0;
  left: 0;
  margin-bottom: 30px;
}

img {
  filter: gray;
  -webkit-filter: grayscale(1);
  -webkit-transition: all .1s ease-in-out;
}

img:hover {
  filter: none;
  -webkit-filter: grayscale(0);
  -webkit-transform: scale(1.01);
}

.image-wrap-2 {
  position: relative;
  text-align: center;
  color: #fff;
}

.image-info {
  z-index: 2;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Home, Page and Edit_Page images*/
.square-container {
  text-align: center;
}

.square-container img {
  width: 25em;
  height: 23em;
  object-fit: cover;
}

.rounded-container img {
  width: 11em;
  height: 11em;
  object-fit: cover;
}

@media (max-width : 400px) {
  .square-container img {
    width: 20em;
    height: 17em;
  }
}

/* Edit Employees Image */
.img-container {
  width: 10em;
  height: 10em;
  overflow: hidden;
}

.img-container img {
  height: 100%;
}

#person-img {
  position: relative;
  left: -30em;
  bottom: 15em;
}

@media(max-width: 992px) {
  .person-container {
    margin-top: 15em;
  }

  #person-img {
    position: relative;
    left: -0.5em;
    bottom: 39em;
  }
}

/*Cookie Consent*/
.footer {
  position: relative;
}

.sticky {
  width: 100%;
  position: absolute;
  top: 10%;
  left: 0;
}